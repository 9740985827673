import React from "react"
import { graphql } from "gatsby"
import Site from "../components/Site"
import TalksPlaylist from "../components/TalksPlaylist"
import "../css/talks.scss"

const Presentation = ({data}) => {
  const thePresentation         = data.datoCmsPresentation
  const allTalks                = [...data.allDatoCmsTalk.edges].map(el => el.node)
  const allFromSamePresentation = [...allTalks].filter(aTalk => aTalk.presentation.slug === thePresentation.slug)

  return (
    <Site
      title     = {`${thePresentation.title}`}
      className = "hcc-talks hcc-talks-presentation"
    >
      <article>
        <TalksPlaylist
          data  = {allFromSamePresentation}
          title = {<><span>Browse All</span> <strong>{thePresentation.title}</strong></>}
        />
      </article>
    </Site>
  )
}

export default Presentation

export const query = graphql`
  query PresentationQuerySingle($slug : String!) {
    datoCmsPresentation(slug: {eq : $slug}) {
      slug
      title
    }
    allDatoCmsTalk(
      sort : {fields : date, order : DESC}
    ) {
      edges {
        node {
          ...TalkFragment
        }
      }
    }
  }
`
