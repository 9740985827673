import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "../components/Button"
import Container from "../components/Container"
import Img from "../components/Img"
import Section from "../components/Section"
import SiteLink from "../components/SiteLink"
import { formatLink, formatTime } from '../helpers'

const TalksPlaylist = ({data, title, moreCount}) => {
  let [showMore, setShowMore] = useState(moreCount)

  return (
    <React.Fragment>
      <Section className="hcc-talks-playlist-intro" data-datocms-noindex>
        <Container>
          <h1>{title}</h1>
        </Container>
      </Section>
      <Section className="hcc-talks-playlist" data-datocms-noindex>
        <Container>
          <ul>
            {data.map((theTalk, i) => {
              if ( i < showMore ) {
                return <TalksPlaylistItem key={`hcc-talks-playlist-item-${theTalk.series.slug}-${theTalk.slug}`} data={theTalk}/>
              }
              return false
            })}
            {(data.length > showMore) &&
              <li className="hcc-talks-playlist-more">
                <Button label="Show More" onClick={() => { let newCount = showMore + moreCount; console.log(newCount); setShowMore(newCount) }}/>
              </li>
            }
          </ul>
        </Container>
      </Section>
    </React.Fragment>
  )
}

TalksPlaylist.defaultProps = {
  data      : [],
  title     : null,
  moreCount : 10,
}

TalksPlaylist.propTypes = {
  data      : PropTypes.array,
  title     : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  moreCount : PropTypes.number,
}

export default TalksPlaylist


// Item
// ----

const TalksPlaylistItem = ({data}) => {
  return (
    <li className="hcc-talks-playlist-item">
      <SiteLink href={formatLink(data.slug, 't')}>
        <Img src={data.series.image.url} alt={`Series: ${data.title}`}/>
        <div className="hcc-talks-playlist-item-content">
          <h2>{data.title}</h2>
          <footer>
            <span>
              {formatTime(data.date, 'talkFull')}
            </span>
            <span>{data.speaker.name}</span>
          </footer>
        </div>
      </SiteLink>
    </li>
  )
}
